import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import axios from "axios";
import authService from "src/services/AuthService";
import { useContext } from "react";
import { AuthContext } from "src/context";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const { setIsAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    if (token) {
      localStorage.setItem("authToken", token);
      console.log(
        "process.env.MAIN_FRONTEND_URL",
        process.env.MAIN_FRONTEND_URL
      );
      // window.location.href = "https://studentoffice.ferpi.uz";
      // window.location.href = "https://office-registration.tiet.uz";
      window.location.href = "https://studentoffice.tkti.uz";

      // navigate({
      //   pathname:process.env.MAIN_FRONTEND_URL
      // }, {replace:true});
    }
  }, [location, navigate]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formdata = new FormData(event.target);
    const { token } = await authService.login({
      email: formdata.get("email"),
      password: formdata.get("password"),
    });
    if (token) {
      localStorage.setItem("authToken", token);
      setIsAuth(true);
    }
  };
  const handleHemisLogin = async (event) => {
    event.preventDefault();
    await authService.hemisLogin();
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Kirish</h1>
                    <p className="text-medium-emphasis">Profilingizga kiring</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Foydalanuvchi email manzili"
                        type="email"
                        autoComplete="email"
                        name="email"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        name="password"
                        placeholder="Parol"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          type="submit"
                          color="primary"
                          className="px-4"
                          style={{ marginRight: "10px" }}
                        >
                          Kirish
                        </CButton>
                        <CButton
                          type="button"
                          onClick={handleHemisLogin}
                          color="primary"
                          className="px-4"
                        >
                          Hemis orqali kirish
                        </CButton>
                      </CCol>
                      {/* <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
